/* html, */
/* body {
    position: relative;
    height: 100%;
    padding: 0;
  } */

body {
    /* background: #f7f7f7; */
    /* font-family: Helvetica Neue, Helvetica, Arial, sans-serif; */
    /* font-size: 14px; */
    /* color: #000; */
    margin: 0;
    padding: 0;
}

/* .nav {
    --bs-nav-link-hover-color: var(--primary-color) !important;
} */

/* .nav-tabs.nav-tabs-basic .nav-link.active,
.nav-tabs.nav-tabs-basic .nav-item.show .nav-link {
    border-color: var(--primary-color) !important;
} */


.search_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: translateY(0);
    letter-spacing: -0.01rem;
    position: relative;
    border: 0px;
    background: white;
}

.search_btn:not(.btn-link):hover {
    transform: none;
    box-shadow: none;
}

.refer_and_earn_border {
    border: 2px dashed #343f52;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    color: var(--bs-btn-active-color);
    /* background-color: var(--primary-color) !important;; */
    border-color: black !important;
    ;
    box-shadow: var(--bs-btn-active-shadow);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: none !important;
}

.swiper-slide {
    text-align: center;
    font-size: 16px;
    background: #fff;
    /* background-color: #f7f7f7; */
}

/* .swiper-slide-category {
    width: 160px !important;
} */

.category-swiper>.swiper-controls>.swiper-navigation {
    display: none;
}

.swiper-slide-category img {
    height: 110px;
    border-radius: 8px;
}

.swiper-wrapper {
    padding: 0 10px 35px 10px;
}


.category-image-container img {
    max-height: 100%;
    border-radius: 8px;
    max-width: 100%;
}

.category-image-container {
    height: 125px !important;
    width: 125px !important;
}

.description_img img {
    max-width: 100% !important;
}

.sub_category-image-container {
    /* height: 100px !important; */
    width: 100px !important;
}

.sub_category-image-container img {
    max-height: 100%;
    max-width: 100%;
    border-radius: 8px;
}

.brand_image_div {
    width: 110px !important;
    height: 130px !important;
}

.brand_image_div img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
}

.progress-wrap.active-progress {
    opacity: 1 !important;
}

/* .category-swiper .swiper-controls .swiper-navigation {
    display: none;
} */

section.slider>.pb-md-1>.swiper-controls {
    position: relative !important;
}

/* .swiper-controls {
    margin-top: 20px;
} */

.swiper-wrapper {
    padding: 0 10px 35px 0px !important;
}

.slide-img {
    max-height: 500px;
    max-width: 1315px;
    /* max-width: 1290px; */
}

.slide-img img {
    width: 100%;
    height: 100%;
}

.swiper-slide img {
    max-width: 100%;
    max-height: 100%;
}

/* .slide-img {
    height: 500px;
} */

/* .swiper-slide-style4 {
    width: 310px !important;
    margin-right: 20px !important;
} */

.logo-img img {
    max-width: 100%;
    max-height: 100%;
}

/* .navbar-brand img {
    width: 50%;
} */

.product-grid .price span,
.striped-price {
    color: red;
    font-size: 13px;
    font-weight: 400;
    text-decoration: line-through;
    margin-left: 3px;
    display: inline-block;
}

button.btn.btn-secondary.dropdown-toggle {
    gap: 2px;
    display: flex;
    align-items: baseline;
}

figure img {
    height: 260px !important;
    max-width: 100% !important;
    width: 100% !important;
}

/* .fig_image{
    height: 150px !important;
} */

.gallery-thumbs .swiper-controls {
    display: none;
}

/* num in counting  */
.num-in {
    background: #FFFFFF;
    border: 2px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    width: 110px;
    float: left;
    margin-top: -22px;
}

.num-in span {
    width: 30%;
    display: block;
    height: 40px;
    float: left;
    position: relative;
}

.num-in span:before,
.num-in span:after {
    content: '';
    position: absolute;
    background-color: #667780;
    height: 2px;
    width: 10px;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -5px;
}

.num-in span.plus:after {
    transform: rotate(90deg);
}

.num-in input {
    float: left;
    width: 60%;
    height: 36px;
    border: none;
    text-align: center;
    font-weight: 900;
    color: #b0b0b0;
}

.num-in input:focus {
    color: black;
    outline: none;
}

/* order details progressbar */
.orders-section #progressbar #step1:before {
    content: "1";
    color: #fff;
    width: 29px;
    text-align: center;
}

.orders-section #progressbar #step2:before {
    content: "2";
    color: #fff;
    width: 29px;
    text-align: center;
}

.orders-section #progressbar #step3:before {
    content: "3";
    color: #fff;
    width: 29px;
    text-align: center;
}

.orders-section #progressbar #step4:before {
    content: "4";
    color: #fff;
    width: 29px;
    text-align: center;
}

.orders-section #progressbar #step5:before {
    content: "5";
    color: #fff;
    width: 29px;
    text-align: center;
}

.orders-section #progressbar #step6:before {
    content: "6";
    color: #fff;
    width: 29px;
    text-align: center;
}

.orders-section #progressbar #step7:before {
    content: "7";
    color: #fff;
    width: 29px;
    text-align: center;
}

section #progressbar div.active:after {
    background: var(--primary-color);
}

@media (max-width: 1024px) {
    .process-wrapper.line [class*=col-]:not(:last-child):after {
        width: 100%;
        position: absolute;
        content: "";
        height: 1px;
        background: none;
        border-top: 1px solid rgba(164, 174, 198, 0.2);
        top: 1.2rem;
        z-index: 1;
    }

}

#step2,
#step3 {
    text-align: center;
}

#step4,
#step5 {
    text-align: right;
}

#step6 {
    text-align: end;
}

#step7 {
    text-align: end;
}

#progressbar div.cancel {
    color: red !important;
}

.orders-section #progressbar div.cancel:after {
    width: 0;
}


.blog_category .select2-container {
    width: 100% !important;
    /* padding-left: 109px; */
}

.blog-img {
    /* width: 300px; */
    height: 230px;
}

.product-list-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.product-bg {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.item-compare {
    margin-top: 54px;
}

.swiper-img {
    border: 1px solid #c2c2c2;
    border-radius: 1.4mm;
    width: 88% !important;
    height: 110px !important;
    display: flex;
    align-items: center;
}

.swiper-img img {
    width: 100% !important;
}

.swiper-image img {
    max-width: 100% !important;
    max-height: 310px !important;
}

/* width */
.product-thumb-img::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.product-thumb-img::-webkit-scrollbar-track {
    border-radius: 7px;
}

/* Handle */
.product-thumb-img::-webkit-scrollbar-thumb {
    background: rgb(178, 174, 174);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
}

.swiper-slide-container>.swiper-controls {
    display: none;
}

.offer-img img {
    max-width: 100%;
    max-height: 100%;
}

.offer-img {
    height: 270px;
}

/* .offer-image {
    height: 330px;
    width: 100%;
} */

.faq_image {
    height: 350px;
    /* width: 430px; */
}

.faq_image img {
    max-width: 100%;
    max-height: 100%;
}

.blog-card-img {
    width: 100% !important;
    height: 300px;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
}

/* .blog-card-img img {
    max-width: 100% !important;
    max-height: 100%;
} */

.fav-row .row {
    margin: 0;
}


.accordion-card {
    position: relative;
    display: flex;
    flex-direction: column;
}

.cart-img {
    width: 110px !important;
    height: 100px !important;
}

.cart-img img {
    max-width: 100% !important;
    max-height: 100% !important;
}

.checkout-img {
    width: 40% !important;
    height: 90px !important;
}

.checkout-img img {
    max-width: 100% !important;
    max-height: 100% !important;
}

.iziModal.isAttached,
.iziModal.isFullscreen {
    border-radius: 12px !important;
}

.compare-img {
    /* width: 100px !important; */
    height: 270px !important;
}

.compare-img img {
    max-width: 100% !important;
    max-height: 100% !important;
}

.reviews .review-box {
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
}

.review-img {
    width: 100% !important;
    height: 90px !important;
}

.review-img img {
    max-width: 100% !important;
    max-height: 100% !important;
}

/* .btn-outline-primary:hover {
    color: white !important;
} */

.refer-img-box {
    height: 250px;
    /* width: 400px; */
}

.refer-img-box img {
    max-width: 100% !important;
    max-height: 100% !important;
}

.footer-logo-footer {
    width: 240px;
    height: 80px;
}

.footer-logo-footer img {
    max-width: 100%;
    max-height: 100%;
}

.list-unstyled li a:hover {
    color: white !important;
}

.link_cursor {
    cursor: pointer;
}

.promo-code-img {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.promo-code-img img {
    max-height: 100%;
    max-width: 100%;
}

.copy-promo-code {
    border: 1px dashed;
    background: #ebebeb;
    max-width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    justify-content: space-between;
}

/* ---------------------------------------------------------------------------------------------- 
      Product-listing 
  */

.product-listing .title {
    font-size: 16px;
}

.sidebar-filter {
    display: none;
}

/* filter sidebar scroll */
.filter_attributes {
    max-height: 300px;
    overflow-y: scroll;
}

.filter_attributes::-webkit-scrollbar {
    width: 7px;
}

.filter_attributes::-webkit-scrollbar-track {
    border-radius: 7px;
}

.filter_attributes::-webkit-scrollbar-thumb {
    background: rgb(66, 66, 66);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
}

/* brand filter sidebar scroll */
.brand_filter {
    max-height: 160px;
    overflow-y: scroll;
}

.title_wrap {
    text-wrap: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

.brand_filter::-webkit-scrollbar {
    width: 7px;
}

.brand_filter::-webkit-scrollbar-track {
    border-radius: 7px;
}

.brand_filter::-webkit-scrollbar-thumb {
    background: rgb(66, 66, 66);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
}

/* HIDE RADIO */
.brand_div [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.brand_div {
    height: 35px !important;
    width: 35px !important;
}

/* IMAGE STYLES */
.brand_div [type=radio]+img {
    cursor: pointer;
    max-height: 35px;
    max-width: 35px;
    object-fit: contain;
}

/* CHECKED STYLES */
.brand_div [type=radio]:checked+img {
    outline: 2px solid var(--primary-color);
}

.selected-brand {
    outline: 2px solid var(--primary-color);
}

.selected-category {
    background-color: var(--primary-color);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPScxLjUnIGZpbGw9JyNmZmYnLz48L3N2Zz4=);
}

/* category filter scroll sidebar */
.category_filter {
    max-height: 210px;
    overflow-y: scroll;
}

.category_filter::-webkit-scrollbar {
    width: 7px;
}

.category_filter::-webkit-scrollbar-track {
    border-radius: 7px;
}

.category_filter::-webkit-scrollbar-thumb {
    background: rgb(66, 66, 66);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
}

/* select2 */

#offcanvas-search .select2-results {
    z-index: 999999;
    position: fixed;
    background: white;
    width: inherit;
    /* overflow: scroll;
    height: 50%; */
}

.select2-results__options {
    max-height: 300px;
    overflow: auto;
}

.select2-result-repository {
    padding-top: 4px;
    padding-bottom: 3px;
}

.select2-result-repository__avatar {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    text-align: center !important;
}

.select2-result-repository__icon {
    float: left;
    width: 60px;
    height: 25px;
    margin-right: 10px;
    text-align: center !important;
}

.select2-result-repository__avatar img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 2px;
}

.select2-result-repository__meta {
    margin-left: 70px;
}

.select2-result-repository__meta_icon {
    margin-left: 0px;
}

.select2-result-repository__title {
    word-wrap: break-word;
    line-height: 1.1;
    margin-bottom: 4px;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers {
    margin-right: 1em;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
    display: inline-block;
    color: #aaa;
    font-size: 11px;
}

.select2-result-repository__description {
    font-size: 13px;
    color: #777;
    margin-top: 4px;
}

.select2-results__option--highlighted {
    opacity: 1 !important;
}

.select2-results__option--highlighted .select2-result-repository__title {
    color: rgb(31, 30, 30);
    color: black;
    font-weight: bold;
}

.select2-results__option--highlighted .select2-result-repository__forks,
.select2-results__option--highlighted .select2-result-repository__stargazers,
.select2-results__option--highlighted .select2-result-repository__description,
.select2-results__option--highlighted .select2-result-repository__watchers {
    color: rgb(31, 30, 30);
}

/* .select2-container--adwitt .select2-results>.select2-results__options {
    max-height: 300px;
    overflow: auto;
} */

.select2-selection__rendered {
    word-wrap: break-word !important;
    text-overflow: hidden !important;
    overflow: hidden;
    white-space: normal !important;
}

.select2-selection__placeholder {
    color: #bcbbbb;
}

.edit_city .select2-container {
    width: 100% !important;
}

/* .select2-container .select2-selection--single { */
/* margin-top: 8px; */
/* padding: 8px;
    height: 100% !important; */
/* } */

.mobile-search .select2-container {
    border: 2px solid rgba(185, 185, 185, .2);
}

.select2-results__options {
    overflow-y: auto;
}

.select2-dropdown {
    z-index: 9999;
    border: 0px solid var(--border-color);
    border-radius: 0px;
}

.select2-search--dropdown .select2-search__field {
    border-radius: 6px;
    padding: 2px;
}

.select2-results__option {
    opacity: 0.8;
    transition: 150ms;
}

.product-page-details .btn-group>.active {
    background-color: #343f52 !important;
    /* background-color: var(--primary-color) !important;  */
}


/* accordion in filter */
.accordion-wrapper .card-header button:before {
    color: black !important;
}

.accordion-wrapper .card-header button:hover::before {
    color: var(--primary-color) !important;
}

.accordion-wrapper .card-header button:hover {
    color: var(--primary-color) !important;
}



@media (min-width: 1024px) {
    .swiper-auto .swiper-slide {
        width: 850px;
    }

    .swiper-auto.swiper-auto-xs .swiper-slide {
        width: 600px;
    }

    .logo-img {
        /* width: 187px !important; */
        height: 50px !important;
    }

    .mobile-searchbar {
        display: none;
    }

    .mobile_quick_view {
        display: none;
    }
}


@media (max-width: 991.98px) {

    .map.rounded-top iframe,
    .image-wrapper.rounded-top {
        border-top-left-radius: 0.4rem !important;
        border-top-right-radius: 0.4rem !important;
        width: 350px !important;
    }

    /* .image-wrapper.rounded-4-top {
      border-top-left-radius: 0.8rem !important;
      border-top-right-radius: 0.8rem !important;
    } */
}


@media (min-width: 768px) and (max-width: 1023.98px) {
    .swiper-auto .swiper-slide {
        width: 600px;
    }

    .swiper-auto.swiper-auto-xs .swiper-slide {
        width: 400px;
    }

    .logo-img {
        /* width: 187px !important; */
        height: 40px !important;
    }

    .mobile-searchbar {
        display: none;
    }

    .mobile_quick_view {
        display: none;
    }
}

@media (max-width: 767px) {
    .swiper-auto.swiper-auto-xs .swiper-slide {
        width: 300px;
    }

    .desktop-search {
        display: none;
    }

    .desktop_quick_view {
        display: none;
    }

    .mySwiper .swiper-wrapper .swiper-mobile-slide {
        width: 190px !important;
    }
}


@media screen and (max-width: 450px) {
    .sidebar-filter-sm {
        display: none;
    }

    .desktop-search {
        display: none;
    }

    .desktop_quick_view {
        display: none;
    }
}

@media screen and (max-width:500px) {
    #chat-iframe {
        width: 90% !important;
    }
}

.sale_tag {
    top: 1rem;
    left: 1rem;
}

/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .swiper-container.swiper-thumbs-container {
        display: none;
    }

    .fig_image {
        height: 150px !important;
    }

    .mt-3 {
        margin-top: 0px !important;
    }

    .mb-13 {
        margin-bottom: 0rem !important;
    }

    /* .isotope{
        height: 0px !important;
    } */

    .default-style .post-header h4 {
        font-size: 14px !important;
        margin-bottom: 0px !important;
    }

    .style_3 .product-content h4 {
        font-size: 14px !important;
    }

    .style_4 .product-content h4 {
        font-size: 14px !important;
    }

    .mySwiper .swiper-wrapper .swiper-mobile-slide {
        width: 190px !important;
    }

    .product_listing_list h4 {
        font-size: 0.75rem;
    }

    .product_listing_list .product-rating-small {
        margin-bottom: 0px !important;
    }

    .sale_tag {
        top: 0.1rem !important;
        left: 0.1rem !important;
    }

    .product_listing_list>.mt-n2 {
        display: none;
    }

    /* .swiper-slide-style4 {
        width: 150px !important;
    } */

    .default_heading h3 {
        font-size: 18px;
    }

    .default_heading h6 {
        font-size: 16px !important;
    }

    .seller-image-container {
        /* width: 150px !important; */
        height: 200px !important;
    }

    .seller-image-container .fig_seller_image {
        width: 150px !important;
        /* height: 150px !important; */
    }

    .sidebar-filter-sm {
        display: none;
    }

    .listing-page .filter-nav {
        display: none;
    }

    .logo-img {
        /* width: 187px !important; */
        height: 75px !important;
    }

    /* .swiper-slide-category {
        width: 100px !important;
        margin-right: 10px !important;
    } */

    .swiper-slide-category img {
        height: 75px;
        border-radius: 8px;
    }
}

@media only screen and (min-width: 800px) {
    .product-preview-image-section-sm {
        display: none;
    }

}

@media (max-width:799px) {
    .swiper-thumbs-container {
        display: none !important;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (max-width: 650px) {

    /* .swiper-container.swiper-thumbs-container {
        display: none;
    } */
    .filter-nav {
        display: none;
    }

    .sidebar-filter-sm {
        display: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (max-width: 768px) {
    .filter-nav {
        display: none;
    }

    .sidebar-filter-sm {
        display: none;
    }

    /* .swiper-slide-category img {
        height: 110px;
    } */
}

/* Extra small devices (phones, 990px and down) */

@media only screen and (max-width: 991px) {
    .product-page-preview-image-section-md {
        display: none;
    }

    .sidebar-filter-sm {
        display: none;
    }

    /* .swiper-slide-category img {
        height: 110px;
    } */
    .logo-img {
        /* width: 187px !important; */
        height: 75px !important;
    }

}

@media only screen and (min-width: 1000px) {

    .filter-sidebar-mobile {
        display: none;
    }

    .slide-img {
        height: 500px;
    }

    .swiper-slide-category img {
        height: 110px;
        border-radius: 8px;
    }

    /* .swiper-slide-category {
        width: 150px !important;
        margin-right: 10px !important;
    } */
}

#pills-tab>.nav-item a:hover {
    color: var(--primary-color) !important;
}

.buy-now-btn {
    position: fixed;
    bottom: 18px;
    left: 18px;
    z-index: 9999;
}

/* color-switcher */

.setting-icon {
    font-size: 1.7rem !important;
    margin-top: 6px;
}

#colors-switcher {
    background: none repeat scroll 0 0 #fff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    left: -189px;
    position: fixed;
    top: 24%;
    width: 189px;
    z-index: 9999;
    padding: 10px 5px
}

#colors-switcher .color-bottom {
    background: none repeat scroll 0 0 #fff
}

#colors-switcher .color-bottom a.settings {
    background: none repeat scroll 0 0 #fff;
    height: 41px;
    position: absolute;
    right: -40px;
    top: 28px;
    width: 40px;
    padding: 3px;
    border-radius: 0 30px 30px 0
}

#colors-switcher ul.color-style {
    list-style: none outside none;
    overflow: hidden;
    padding: 0;
    border-radius: 0px;
}

.list-item-inline {
    display: inline-block;
}

#colors-switcher ul.color-style li.list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 0
}

#colors-switcher ul.color-style li a {
    cursor: pointer;
    display: block;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin: 3px
}

#colors-switcher ul.color-style .blue {
    background-color: #1e4071;
}

#colors-switcher ul.color-style .aqua {
    background-color: #54a8c7;
}

#colors-switcher ul.color-style .fuchsia {
    background-color: #e668b3;
}

#colors-switcher ul.color-style .grape {
    background-color: #605dba;
}

#colors-switcher ul.color-style .green {
    background-color: #45c4a0;
}


#colors-switcher ul.color-style .leaf {
    background-color: #7cb798;
}

#colors-switcher ul.color-style .navy {
    background-color: #343f52;
}

#colors-switcher ul.color-style .orange {
    background-color: #f78b77;
}

#colors-switcher ul.color-style .pink {
    background-color: #d16b86;
}

#colors-switcher ul.color-style .purple {
    background-color: #747ed1;
}

#colors-switcher ul.color-style .red {
    background-color: #e2626b;
}

#colors-switcher ul.color-style .sky {
    background-color: #5eb9f0;
}

#colors-switcher ul.color-style .violet {
    background-color: #a07cc5;
}

.product-preview-image-section-sm .swiper-controls .swiper-navigation .swiper-button {
    display: none;
}

.swiper-style4 .swiper-controls .swiper-navigation .swiper-button {
    display: none;
}

.swiper-navigation .swiper-button {
    display: none;
}

.whatsapp-icon {
    position: fixed;
    left: 20px;
    bottom: 65px;
    z-index: 9999;
    width: 45px;
    height: 45px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 3px 0px; */
}

.whatsapp-icon .btn {
    padding: 0 !important;
}

.whatsapp-icon .btn img {
    max-width: 100%;
    max-height: 100%;
}

/* chat css */

.chat-hide-show {
    display: none !important;
}

.chat-min {
    /* height: 73vh !important; */
    height: 100% !important;
    max-height: 850px;
}

.image-box-100 {
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: 100px;
    height: 100px;
}

.image-box-100 img {
    max-width: 100%;
    max-height: 100%;
}

.chat-min>.select2-container {
    width: 100% !important;
}

.chat-max {
    height: 88vh !important;
}

.chat-theme-dark {
    background-color: #1A1D21 !important;
    border-top: 2px solid #17a2b8 !important;
}

.chat-theme-light {
    background-color: #F8F8FA !important;
    border-top: 2px solid #383F45 !important;
}

.chat-theme-light .chat-left .person-group-chat {
    background: #8e8e8e;
    padding: 1px 5px;
    border-radius: 3px;
    position: absolute;
    color: #ececec;
    top: -22px;
    left: 0;
    font-size: 12px;
}

.chat-theme-light .chat-right .person-group-chat {
    background: #8e8e8e;
    padding: 1px 5px;
    border-radius: 3px;
    position: absolute;
    color: #ececec;
    top: -22px;
    right: 0;
    font-size: 12px;
}

.chat-avtar-search {
    border-radius: 50%;
    border: none;
    width: 50px;
    height: 50px;
    background: #99e2ff;
    text-align: center;
    line-height: 52px;
    font-size: 26px;
    font-weight: 800;
    color: #777777;
    margin: 0px 16px 0px 0px;
}

.chat-avtar-main {
    border-radius: 50%;
    border: none;
    width: 45px;
    height: 45px;
    background: #e1ebff;
    text-align: center;
    line-height: 52px;
    font-size: 26px;
    font-weight: 800;
    color: #777777;
}

.chat-box .chat-content .chat-item>.chat-avtar {
    float: left;
    /*    border-radius: 50%;
    border: none;
    width: 50px;
    height: 50px;
    background: #e1ebff;
    text-align: center;
    line-height: 52px;
    font-size: 26px;
    font-weight: 800;
    color: #777777;*/
    -webkit-user-drag: none !important;
}

.chat-box .chat-content .chat-item.chat-right .chat-avtar {
    float: right;
    /*    border-radius: 50%;
    border: none;
    width: 50px;
    height: 50px;
    background: #ffe1e1;
    text-align: center;
    line-height: 52px;
    font-size: 26px;
    font-weight: 800;
    color: #777777;*/
    -webkit-user-drag: none;
}

.chat-theme-light .delete-msg {
    position: absolute;
    color: white;
    top: 0;
    right: 3px;
}

.chat-theme-dark .delete-msg {
    position: absolute;
    color: white;
    top: 0;
    right: 3px;
}

.chat-theme-light .download-msg {
    position: absolute;
    color: black;
    top: 0;
    left: 3px;
}

.chat-theme-dark .download-msg {
    position: absolute;
    color: black;
    top: 0;
    left: 3px;
}

.chat_media_img {
    grid-area: img;
}

.chat_media_file {
    grid-area: file;
    color: #b13c3c;
}

.chat_media_size {
    grid-area: size;
}


.chat-theme-light .chat-files {
    text-align: center;
    display: grid;
    grid-template-areas:
        'img file file file size size';
    grid-gap: 10px;
    background-color: #cccccc;
    border-radius: 3px;
    padding: 10px;
    color: black;
    margin: 1px;
}

.chat-theme-dark .chat-files {
    text-align: center;
    display: grid;
    grid-template-areas:
        'img file file file size size';
    grid-gap: 10px;
    background-color: #cccccc;
    border-radius: 3px;
    padding: 10px;
    color: black;
    margin: 1px;
}

.chat-files-search {
    text-align: center;
    display: grid;
    grid-template-areas:
        'img file file file size fa-download';
    grid-gap: 10px;
    background-color: #cccccc;
    border-radius: 3px;
    padding: 10px;
    color: black;
    margin: 1px;
}

.chat-theme-light .chat-image-view {
    position: relative;
    background-color: #cccccc;
    border-radius: 3px;
    padding: 5px;
    color: black;
    margin: 1px;
}


.chat-theme-light .msg_text_media {
    display: grid;
}

.chat-theme-dark .chat-image-view {
    position: relative;
    background-color: #cccccc;
    border-radius: 3px;
    padding: 5px;
    color: black;
    margin: 1px;
}

.chat-theme-dark .msg_text_media {
    display: grid;
}

.chat-theme-light .download-btn-styling {
    background: #27ab45;
    color: black;
    padding: 7px;
    border-radius: 3px;
    display: none;
    margin: 8px 5px 0px 0px;
}

.chat-theme-dark .download-btn-styling {
    background: #27ab45;
    color: black;
    padding: 7px;
    border-radius: 3px;
    display: none;
    margin: 8px 5px 0px 0px;
}

.chat-image-view:hover .download-btn-styling {
    display: block;
}

.chat-files:hover .download-btn-styling {
    display: block;
}


.chat-theme-light .go-to-bottom-btn {
    cursor: pointer;
    padding: 6px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white !important;
    border: none;
    position: absolute;
    top: -48%;
    right: 0px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 7px 4px #00000036;
    display: none;
    z-index: 2;
}

.chat-theme-dark .go-to-bottom-btn {
    cursor: pointer;
    padding: 6px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white !important;
    border: none;
    position: absolute;
    top: -48%;
    right: 0px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 7px 4px #00000036;
    display: none;
    z-index: 2;
}

.chat-theme-dark .chat-preview-btn {
    position: absolute;
    top: 30%;
    right: 60px;
    color: #868686 !important;
}

.chat-theme-light .chat-preview-btn {
    position: absolute;
    top: 30%;
    right: 60px;
    color: #414141 !important;
}

.chat-theme-dark .chat-preview-btn:hover {
    color: #5a5a5a !important;
}

.chat-theme-light .chat-preview-btn:hover {
    color: #303030 !important;
}

.chat-theme-dark .chat-time {
    color: #d8d8d8 !important;
}

.chat-theme-light .new-msg-rcv {
    font-weight: 1000 !important;
    color: #383F45 !important;
}

.chat-theme-dark .new-msg-rcv {
    font-weight: 1000 !important;
    color: #FFFFFF !important;
}

.chat-theme-light .chat-bg {
    background-image: url(../images/bg-chat.jpg) !important;
}

.chat-theme-light .text-successg {
    color: #39E500 !important;
}

.chat-theme-dark .chat-bg {
    background-color: #303335 !important;
}

.chat-theme-dark .text-success {
    color: #39E500 !important;
}

.chat-theme-dark .chat-search-box {
    background-color: #1a1d21 !important;
    border: 1px solid #a6a7ab !important;
    border-radius: .25rem !important;
    margin-right: 8px !important;
    height: 30px !important;
    width: -webkit-fill-available;
}

.chat-theme-dark .chat-search-box:hover {
    background-color: #363b42 !important;
    border: 1px solid #c9cacc !important;
}

.chat-theme-dark .chat-search-box:focus {
    background-color: #363b42 !important;
    border: 1px solid #c9cacc !important;
    color: #c9cacc !important;
}

.chat-theme-light .chat-search-box {
    border-radius: .25rem !important;
    margin-right: 8px !important;
    height: 30px !important;
    width: -webkit-fill-available;
}

.chat-theme-light .chat-search-box:hover {
    background-color: #f2f2f7;
    border-color: #d9dae4;
}

.chat-theme-dark .chat-search-btn {
    background-color: #1a1d21 !important;
    border-color: #a6a7ab !important;
}

.chat-scroll {
    overflow: scroll !important;
    outline: none !important;
}

/* width */
.chat-scroll::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.chat-scroll::-webkit-scrollbar-track {
    border-radius: 7px;
}

/* Handle */
.chat-scroll::-webkit-scrollbar-thumb {
    background: rgb(66, 66, 66);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
}

.chat-theme-dark .active {
    font-weight: 700 !important;
    background: #3abaf4;
    padding: 3px 15px;
    color: #FFFDF9 !important;
}

.chat-theme-dark .active:hover {
    background: #3abaf4 !important;
    color: #FFFDF9 !important;
}

.chat-theme-light .active {
    font-weight: 700 !important;
    background: #3abaf4;
    padding: 3px 15px;
    color: #FFFDF9 !important;
}

.chat-theme-light .active:hover {
    background: #3abaf4 !important;
    color: #FFFDF9 !important;
}

.chat-theme-dark .chat-person {
    font-weight: 700;
    color: #ababab;
    padding: 3px 15px;
}

.chat-theme-dark code {
    background: #e8e8e8;
    padding: 6px 8px;
    border-radius: 4px;
}

.chat-theme-light code {
    background: #e8e8e8;
    padding: 6px 8px;
    border-radius: 4px;
}

.chat-theme-dark .chat-person:hover {
    background: rgb(0, 0, 0);
    cursor: pointer;
}

.chat-theme-light .chat-person {
    font-weight: 500;
    color: #4f5961;
    padding: 3px 15px;
}

.chat-theme-light .chat-person:hover {
    background: #FFFFFF;
    cursor: pointer;
}

.chat-theme-dark .text-color {
    color: #ababab !important;

}

.chat-theme-light .text-color {
    color: #383F45 !important;
}

.chat-theme-dark .text-color h4 {
    color: #ababab !important;
}

.chat-theme-light .text-color h4 {
    color: #383F45 !important;
}

.chat-theme-dark .theme-inputs {
    background-color: #1a1d21 !important;
    border: 1px solid #a6a7ab !important;
    border-radius: 6px !important;
    color: #c9cacc !important;
    height: auto !important;
    white-space: pre-wrap !important;
}

.chat-theme-light .theme-inputs {
    border: 1px solid #383F45 !important;
    border-radius: 6px !important;
    height: auto !important;
    white-space: pre-wrap !important;
}

.chat-card-header {
    line-height: 9px !important;
    min-height: 0px !important;
    padding: 5px 8px !important;
    border-bottom: 0px !important;
}

.chat-card-header h4 {
    font-size: 17px !important;
    font-weight: 500;
}

.chat-list-unstyled-border li {
    border-bottom: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

.chat-card-body {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    flex: 0 !important;
}

.chat-img-undrag {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}


.chat_divider {
    padding: 8px 10px;
    text-align: center;
    font-size: medium;
    color: brown;
    margin: 0 0 20px 0px;
    display: flex;
    align-items: center;
    text-align: center;
}

.chat_divider::before,
.chat_divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #cf9a5e;
}

.chat_divider::before {
    margin-right: .25em;
}

.chat_divider::after {
    margin-left: .25em;
}


.chat_loader {
    padding: 8px 10px;
    text-align: center;
    font-size: medium;
    color: brown;
    margin: 0 0 20px 0px;
    display: flex;
    align-items: center;
    text-align: center;
    display: none;
}

.chat_loader::before,
.chat_loader::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #cf9a5e;
}

.chat_loader::before {
    margin-right: .25em;
}

.chat_loader::after {
    margin-left: .25em;
}


#chat-input-textarea-result {
    background-color: rgba(117, 117, 117, 0.36);
    position: absolute;
    bottom: 51px;
    border: 1.5px dashed rgb(119, 122, 125) !important;
    border-radius: 6px !important;
    height: auto;
}

.badge-chat {
    vertical-align: middle;
    border-radius: 5px;
    float: right;
    background-color: #fc544b;
    color: white;
    /* display: inline-block; if you get any error from this class then first uncmnt this and check */
    padding: .30em 1em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    margin-top: 2px;
}

#chat-button.opened,
#chat-iframe.opened {
    transition: all 0.35s ease-in-out;
    box-shadow: 0px 19px 10px 2px #00000014
}

#chat-iframe {
    border-radius: 26px;
}

.chat-iframe {
    display: none;
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 450px;
    height: 600px;
    border: none;
    z-index: 999;
}

.fixed-icon {
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
}

.progress-wrap {
    position: initial !important;
}

#chat-button {
    transition: all 0.35s ease-in-out;
    /* position: fixed;
    bottom: 32px;
    right: 83px; */
    padding: 8px 14px;
    background-color: #3cc766;
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
    z-index: 999;
    cursor: pointer;
    /* height: 2.5rem;
    width: 2.5rem; */
    display: flex;
    justify-content: center;
}


.avtar_img {
    height: 45px !important;
}


#floating_chat_view {
    margin: 22px;
}

.seller-profile-card {
    border-radius: 50%;
    width: 150px;
    /* background-color: antiquewhite; */
    height: 150px;

}

.seller-profile-card img {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: cover !important;
    border-radius: 50%;
}

#pickup_from_store:active {
    border: var(--primary-color) !important;
    /* color: var(--primary-color) !important; */
}

.product-styl>.swiper-style4>.swiper-controls {
    display: none;
}

.btn-xs,
.btn-group-xs>.btn {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.8rem;
    --bs-btn-font-size: 0.6rem;
    --bs-btn-border-radius: 0.4rem;
}

.avatar {
    font-size: 12px !important;
    height: 42px !important;
    width: 42px !important;
}

.default-style {
    position: relative !important;
    top: 0px !important;
    left: 0% !important;
}

.fav_button_dif {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: translateY(0);
    letter-spacing: -0.01rem;
    position: relative;
    --bs-btn-padding-y: 0.4rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 0.7rem;
    --bs-btn-border-radius: 0.4rem;
    /* --bs-btn-padding-x: 1.2rem;
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-font-size: 0.8rem;
    --bs-btn-border-radius: 0.4rem; */

    --bs-btn-color: #e2626b;
    --bs-btn-border-color: #e2626b;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #e2626b;
    --bs-btn-hover-border-color: #e2626b;
    --bs-btn-focus-shadow-rgb: 226, 98, 107;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #e2626b;
    --bs-btn-active-border-color: #e2626b;
    --bs-btn-active-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15);
    --bs-btn-disabled-color: #e2626b;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #e2626b;
    --bs-gradient: none;

    --bs-btn-font-family: ;
    --bs-btn-font-weight: 700;
    --bs-btn-line-height: 1.7;
    /* --bs-btn-color: #60697b; */
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 2px;
    /* --bs-btn-border-color: transparent; */
    /* --bs-btn-hover-border-color: transparent; */
    --bs-btn-box-shadow: unset;
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    /* display: inline-block; */
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    /* font-family: var(--bs-btn-font-family); */
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    /* cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    box-shadow: var(--bs-btn-box-shadow);
    transition: all 0.2s ease-in-out;
}

.fav_button_dif:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}

.product-page-div>.fa-heart:hover {
    color: white !important;
}

.empty-compare {
    height: 300px;
    width: 300px;
}

.empty-compare img {
    max-width: 100%;
    max-height: 100%;
}

.no-promo-code-img {
    height: 250px;
    width: 270px;
}

.no-promo-code-img img {
    max-width: 100%;
    max-height: 100%;
}

.select2-container {
    width: 100% !important;
}

.mobile-search .select2-container {
    border-radius: 10px !important;
}

.mobile-search .select2-selection {
    min-height: 28px !important;
    border-radius: 10px !important;
}

#cart-count {
    position: relative;
    left: -8px;
}

.profile_image .avatar {
    height: 110px !important;
    width: 110px !important;
    object-fit: cover;
}

.theme-krajee-svg.rating-xs .krajee-icon,
.theme-krajee-svg.rating-xs .krajee-icon-clear {
    width: 0.8rem !important;
    height: 0.8rem !important;
}

.theme-krajee-svg .filled-stars .krajee-icon-star {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBoZWlnaHQ9IjMycHgiIHdpZHRoPSIzMnB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHZpZXdCb3g9IjAgMCA0OC45NCA0Ny45NCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iI2FhYSIgc3Ryb2tlPSIjZmZmZiI+Cgo8ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCIvPgoKPGcgaWQ9IlNWR1JlcG9fdHJhY2VyQ2FycmllciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2U9IiNDQ0NDQ0MiIHN0cm9rZS13aWR0aD0iMC4zODM1MTk5OTk5OTk5OTk5NyIvPgoKPGcgaWQ9IlNWR1JlcG9faWNvbkNhcnJpZXIiPiA8cGF0aCBzdHlsZT0iZmlsbDogI0ZGQ0IwMDsiIGQ9Ik0yNi4yODUsMi40ODZsNS40MDcsMTAuOTU2YzAuMzc2LDAuNzYyLDEuMTAzLDEuMjksMS45NDQsMS40MTJsMTIuMDkxLDEuNzU3IGMyLjExOCwwLjMwOCwyLjk2MywyLjkxLDEuNDMxLDQuNDAzbC04Ljc0OSw4LjUyOGMtMC42MDgsMC41OTMtMC44ODYsMS40NDgtMC43NDIsMi4yODVsMi4wNjUsMTIuMDQyIGMwLjM2MiwyLjEwOS0xLjg1MiwzLjcxNy0zLjc0NiwyLjcyMmwtMTAuODE0LTUuNjg1Yy0wLjc1Mi0wLjM5NS0xLjY1MS0wLjM5NS0yLjQwMywwbC0xMC44MTQsNS42ODUgYy0xLjg5NCwwLjk5Ni00LjEwOC0wLjYxMy0zLjc0Ni0yLjcyMmwyLjA2NS0xMi4wNDJjMC4xNDQtMC44MzctMC4xMzQtMS42OTItMC43NDItMi4yODVsLTguNzQ5LTguNTI4IGMtMS41MzItMS40OTQtMC42ODctNC4wOTYsMS40MzEtNC40MDNsMTIuMDkxLTEuNzU3YzAuODQxLTAuMTIyLDEuNTY4LTAuNjUsMS45NDQtMS40MTJsNS40MDctMTAuOTU2IEMyMi42MDIsMC41NjcsMjUuMzM4LDAuNTY3LDI2LjI4NSwyLjQ4NnoiLz4gPC9nPgoKPC9zdmc+') !important
}

.theme-krajee-svg .empty-stars .krajee-icon-star {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBoZWlnaHQ9IjMycHgiIHdpZHRoPSIzMnB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHZpZXdCb3g9IjAgMCA0OC45NCA0Ny45NCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iI2FhYSIgc3Ryb2tlPSIjZmZmZiI+Cgo8ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCIvPgoKPGcgaWQ9IlNWR1JlcG9fdHJhY2VyQ2FycmllciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2U9IiNDQ0NDQ0MiIHN0cm9rZS13aWR0aD0iMC4zODM1MTk5OTk5OTk5OTk5NyIvPgoKPGcgaWQ9IlNWR1JlcG9faWNvbkNhcnJpZXIiPiA8cGF0aCBzdHlsZT0iLyohIGZpbGw6ICNlYWJlMTI7ICovIiBkPSJNMjYuMjg1LDIuNDg2bDUuNDA3LDEwLjk1NmMwLjM3NiwwLjc2MiwxLjEwMywxLjI5LDEuOTQ0LDEuNDEybDEyLjA5MSwxLjc1NyBjMi4xMTgsMC4zMDgsMi45NjMsMi45MSwxLjQzMSw0LjQwM2wtOC43NDksOC41MjhjLTAuNjA4LDAuNTkzLTAuODg2LDEuNDQ4LTAuNzQyLDIuMjg1bDIuMDY1LDEyLjA0MiBjMC4zNjIsMi4xMDktMS44NTIsMy43MTctMy43NDYsMi43MjJsLTEwLjgxNC01LjY4NWMtMC43NTItMC4zOTUtMS42NTEtMC4zOTUtMi40MDMsMGwtMTAuODE0LDUuNjg1IGMtMS44OTQsMC45OTYtNC4xMDgtMC42MTMtMy43NDYtMi43MjJsMi4wNjUtMTIuMDQyYzAuMTQ0LTAuODM3LTAuMTM0LTEuNjkyLTAuNzQyLTIuMjg1bC04Ljc0OS04LjUyOCBjLTEuNTMyLTEuNDk0LTAuNjg3LTQuMDk2LDEuNDMxLTQuNDAzbDEyLjA5MS0xLjc1N2MwLjg0MS0wLjEyMiwxLjU2OC0wLjY1LDEuOTQ0LTEuNDEybDUuNDA3LTEwLjk1NiBDMjIuNjAyLDAuNTY3LDI1LjMzOCwwLjU2NywyNi4yODUsMi40ODZ6Ii8+IDwvZz4KCjwvc3ZnPg==') !important
}

.bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
    z-index: 1050;
}

.bottom-sheet.show {
    transform: translateY(0);
}

.logo-fit {
    object-fit: contain;
}

.image-fit {
    object-fit: cover;
}

.payment-gateway-images {
    height: 30px;
}

.under_maintenance {
    max-width: 450px;
}

.tab_border {
    border: none;
}

.product_other_images {
    object-fit: contain;
    height: 450px !important;
    width: fit-content;
}

.product_main_image {
    width: 114px;
    margin-right: 10px;
}

.overflow-height {
    height: 530px;
}

.slide_image {
    width: 280px;
    margin-right: 30px;
}

.gray_slale_cod {
    filter: grayscale(100%);
}

#search_items {
    list-style: none;
    /* Remove bullets */
    margin: 0;
    padding: 0;
    max-height: 300px;
    /* Fixed height for search results */
    overflow-y: scroll;
    /* Adds scrollbar if content exceeds the height */
}

#search_items::-webkit-scrollbar {
    width: 7px;
}

#search_items::-webkit-scrollbar-track {
    border-radius: 7px;
}

#search_items::-webkit-scrollbar-thumb {
    background: rgb(66, 66, 66);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
}

.search-products {
    min-width: 64%;
}

.w-62 {
    width: 62% !important;
}

.item {
    padding: 2px;
    /* border-bottom: 1px solid #eee;  */
}

.item .item-title {
    color: #000;
    /* Text color */
    text-decoration: none;
}

/* .item .item-title:hover {
    text-decoration: underline;
} */

.empty {
    color: #999;
    font-style: italic;
}

.select2-selection__rendered {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* support ticket chat  */

.direct-chat-text {
    border-radius: .3rem;
    background: #d2d6de;
    border: 1px solid #d2d6de;
    color: #444;
    margin: 5px 0 0 50px;
    padding: 5px 10px;
    position: relative;
}

.direct-chat-text {
    width: fit-content;
}

.right .direct-chat-text {
    float: right;
}

.direct-chat-timestamp {
    margin: 0 10px;
}

.direct-chat-text {
    margin: 5px 0 0 10px;
}

.right .direct-chat-text {
    margin-right: 10px;
}

.direct-chat-messages {
    height: 384px;
}

.direct-chat-primary .right>.direct-chat-text {
    background: #007bff;
    border-color: #007bff;
    color: #fff;
}

.direct-chat-name {
    font-weight: 600;
}

.direct-chat-timestamp {
    color: #697582;
}